@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .default-link {
    @apply text-[#00A8E8]  hover:underline hover:decoration-[#00A8E8] ;
  }
  }
  
@import "~react-image-gallery/styles/css/image-gallery.css";
